import Vue from 'vue'
import vueAnalytics from 'vue-analytics'
import Cookie from 'js-cookie'

export default ({ store, app }) => {
  // check stored cookies
  const disableGA =
    Cookie.get('allow-analytics') !== '1' && Cookie.get('allow-analytics') !== 1

  Vue.use(vueAnalytics, {
    id: app.$config.GA_ID,
    ignoreRoutes: ['/administration'],
    disabled: !app.$config.APP_ENV === 'production' || disableGA,
    // disabled: true,
    config: {
      anonymize_ip: true,
    },
    // TODO: check, turn off in production
    // debug: {
    //   enabled: process.env.BASE_URL !== 'production' || false,
    //   trace: process.env.BASE_URL !== 'production' || false,
    //   sendHitTask: process.env.BASE_URL !== 'production' || false,
    // },
    debug: {
      enabled: false,
      sendHitTask: true,
    },
    ready() {
      // console.log('ready to track!')
    },
  })

  if (disableGA) {
    const domain = app.$config.BASE_URL || 'localhost'
    store.$cookies.remove('_ga-name')
    store.$cookies.remove('_ga')
    store.$cookies.remove('_gid')
    store.$cookies.remove('_gat')
    store.$cookies.remove('ga')
    store.$cookies.remove('_ga-name', { path: '/', domain })
    store.$cookies.remove('_ga', { path: '/', domain })
    store.$cookies.remove('_gid', { path: '/', domain })
    store.$cookies.remove('_gat', { path: '/', domain })

    try {
      const home = '.myuse.ie'
      store.$cookies.remove('_ga-name', { path: '/', home })
      store.$cookies.remove('_ga', { path: '/', home })
      store.$cookies.remove('_gid', { path: '/', home })
      store.$cookies.remove('_gat', { path: '/', home })
    } catch (e) {
      // console.log(e)
    }
  }
}
