// https://github.com/js-cookie/js-cookie
/*
 * User credentials and preferences, facilitates registration and login
 * Cookie and tenancy settings also stored here also stored here
 */
import Cookies from 'js-cookie'

const getDefaultState = () => {
  return {
    user: null,
    auth_token: null,
    logged_in_time: null,
  }
}

// initial state
export const state = () => getDefaultState()

export const mutations = {
  // delete the current navigation state
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  // set the users API token
  set_token(state, token) {
    state.auth_token = token
  },
  // set the time the user logged in at
  set_logged_in_time(state, date) {
    state.logged_in_time = date
  },
  // set the users data
  set_user(state, data) {
    state.user = data.user
  },
}
export const actions = {
  // register a standard user
  register_user({ dispatch }, data) {
    // delete previous user
    dispatch('logoutUser', { invisible: true })

    let url
    if (this.$config.APP_ENV === 'development') {
      url = '/auth/register_test_user'
    } else {
      url = '/auth/register_user'
    }

    return new Promise((resolve, reject) => {
      this.$axios
        .$post(this.$config.BASE_API + url, {
          batch_id: data.batch_id,
          token: data.token,
          key: data.key,
          captcha: data.captcha,
          enable_ga: Cookies.get('allow-analytics') === '1' ? 1 : 0,
          domain: data.domain,
          remember_me: Cookies.get('remember-me') === '1' ? 1 : 0,
        })
        .then((response) => {
          // todo: make this a 400 response
          if ('status' in response) {
            // console.log('captcha has failed', response)
            return
          }

          // assign user
          dispatch('apply_user', response)

          this.$ga.event(
            'User Register',
            'register',
            response.user.batch_id,
            response.user.user_uuid
          )
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  login_user({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(this.$config.BASE_API + '/auth/login_user', {
          password: data.password,
        })
        .then((response) => {
          // set cookies
          dispatch('setRememberMePreference', response.remember_me)
          dispatch('setGAPreference', response.enable_ga)



          // assign user
          dispatch('apply_user', response)

          this.$ga.event(
            'User Login',
            'register',
            response.user.batch_id,
            response.user.user_uuid
          )

          // recall this users profile
          dispatch('profile/recallProfile', response.user, {
            root: true,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  apply_user({ commit, dispatch }, response) {
    commit('set_token', response.token)
    commit('set_logged_in_time', new Date())
    commit('set_user', response)

    // set content mode settings
    dispatch(
      'processors/setDistributionSettings',
      { tree: response.tree, batch: response.batch_id },
      {
        root: true,
      }
    )
  },

  // logout current user and strip credentials
  async logoutUser({ state, dispatch }, invisible) {
    // remove all user data
    dispatch('clearAllVuex')

    // mark this event in GA
    if (state.user) {
      // Logout user and invalidate tokens
      await this.$axios
        .$post(this.$config.BASE_API + '/auth/logout_user', {
          password: state.user.password,
        })
        .then(() => {
          this.$ga.event('User Logout', 'logout') // removed user uuid from here
        })
        .catch((e) => {
          // console.log(e)
        })
    }

    // display logout to the user and redirect
    if (!invisible) {
      this.$router.push({
        path: '/',
      })
      setTimeout(() => {
        this._vm.$dialog.message.info('You have been logged out.', {
          position: 'top',
          color: 'primary',
          timeout: 4000,
        })
      }, 600)
    }
  },
  // logout current user and strip credentials
  async clearAllVuex({ dispatch, commit }) {
    // clear profile/nav/feedback history
    await dispatch('profile/resetState', '', { root: true })
    await dispatch('navigation/resetState', '', { root: true })
    await dispatch('feedback/resetState', '', { root: true })
    await commit('RESET_STATE')
    window.localStorage.clear()
  },

  setGAPreference({ dispatch }, pref) {
    Cookies.set('allow-analytics', pref, { expires: 30 })
    dispatch('changeGAState', pref)
    dispatch('storeCookiePrefs')
  },

  setRememberMePreference({ dispatch }, pref) {
    Cookies.set('remember-me', pref, { expires: 30 })
    dispatch('storeCookiePrefs')
  },
  // set the user cookie prefs
  storeCookiePrefs({ state }) {
    if (state.user) {
      // set the user cookie settings
      this.$axios.$post(this.$config.BASE_API + '/activity/set_cookie_settings', {
        enable_ga: Cookies.get('allow-analytics') === '1' ? 1 : 0,
        remember_me: Cookies.get('remember-me') === '1' ? 1 : 0,
      })
    }
  },
  async changeGAState({ state }, preference) {
    if (preference === 1 && state.user) {
      // enable GA
      this.$ga.enable()

      // set batch ID in GA
      await this.$ga.set('dimension1', state.user.batch_id)

      // set tenant ID in GA
      if (state.user.tenant_id) {
        await this.$ga.set('dimension2', state.user.tenant_id)
      }

      // set user ID in GA
      await this.$ga.set('dimension3', state.user.user_uuid)
    } else {
      // disable google analyitcs
      this.$ga.disable()

      // remove all related cookies
      try {
        window.ga('remove')

        Cookies.remove('_ga', { path: '', domain: 'localhost' })
        Cookies.remove('_gid', { path: '', domain: 'localhost' })
        Cookies.remove('_gat', { path: '', domain: 'localhost' })

        Cookies.remove('_ga', { path: '', domain: '.myuse.ie' })
        Cookies.remove('_gid', { path: '', domain: '.myuse.ie' })
        Cookies.remove('_gat', { path: '', domain: '.myuse.ie' })

        Cookies.remove('_ga', { path: '', domain: '.myuse-test.ie' })
        Cookies.remove('_gid', { path: '', domain: '.myuse-test.ie' })
        Cookies.remove('_gat', { path: '', domain: '.myuse-test.ie' })

        this.$cookies.remove('_ga-name')
        this.$cookies.remove('_ga')
        this.$cookies.remove('_gid')
        this.$cookies.remove('_gat')
        this.$cookies.remove('_ga-name', {
          path: '',
          domain: 'localhost',
        })
        this.$cookies.remove('_ga', { path: '', domain: 'localhost' })
        this.$cookies.remove('_gid', { path: '', domain: 'localhost' })
        this.$cookies.remove('_gat', { path: '', domain: 'localhost' })

        try {
          const home = '.myuse.ie'
          this.$cookies.remove('_ga-name', { path: '/', home })
          this.$cookies.remove('_ga', { path: '/', home })
          this.$cookies.remove('_gid', { path: '/', home })
          this.$cookies.remove('_gat', { path: '/', home })
        } catch (e) {
          // console.log(e)
        }
      } catch (ex) {
        // console.log(ex)
      }
    }
  },
}

export const getters = {
  // get the current user
  getUser(state) {
    return state.user
  },

  // get the current user
  getUserPassword(state) {
    return state.user.password || null
  },

  // get the assigned API token
  getToken(state) {
    return state.user.auth_token
  },
  // get the time the user logged in
  getRememberMeState(state) {
    return state.remember_me_session_preference
  },

  // get the time the user logged in
  getGAState(state) {
    return state.ga_preference
  },

  // get the time the user logged in
  getLastLogin(state) {
    return state.logged_in_time
  },

  // get cookie storing remember me preference
  getRememberMeCookieStatus() {
    return parseInt(Cookies.get('remember-me') || 0)
  },

  // get cookie storing GA preference
  getGACookieStatus() {
    return parseInt(Cookies.get('allow-analytics') || 0)
  },
}
