import copycode from '../content/general/copycode.json'
import dast from '../content/activities/activities-dast.json'
import quiz from '../content/activities/activities-quiz.json'
import body from '../content/activities/activities-body.json'
import labels from '../content/general/site-navigation.json'
import modules from '../content/general/modules--lister.json'

/*
 * Find content for myuse pages (todo: these may be better outside of state)
 */

// default state
const getDefaultState = () => {
  return {
    copycode,
    dast,
    quiz,
    labels,
    modules,
    body,
  }
}

// initial state
export const state = getDefaultState()

export const mutations = {
  // delete the current navigation state
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  // reset some state variables when brought back to main page
  resetState({ commit }) {
    commit('RESET_STATE')
  },
}

// todo: all items should have getters
export const getters = {
  getBodyFeedbackContent: (state) => {
    return state.body
  },
  getQuizContent: (state) => {
    return state.quiz
  },
  getLabels: (state) => {
    return state.labels
  },
  getModuleContent: (state) => {
    return state.modules
  },
}
