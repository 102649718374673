import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c4c70b0e = () => interopDefault(import('../pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _b4d55b82 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _68893392 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _099ecc1a = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _347caad3 = () => interopDefault(import('../pages/page/_page.vue' /* webpackChunkName: "pages/page/_page" */))
const _744e7a06 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibility",
    component: _c4c70b0e,
    name: "accessibility"
  }, {
    path: "/dashboard",
    component: _b4d55b82,
    name: "dashboard"
  }, {
    path: "/page",
    component: _68893392,
    name: "page"
  }, {
    path: "/privacy",
    component: _099ecc1a,
    name: "privacy"
  }, {
    path: "/page/:page",
    component: _347caad3,
    name: "page-page"
  }, {
    path: "/",
    component: _744e7a06,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
