export default function ({ app, $axios, redirect, store }) {
  // check on every request
  $axios.onRequest((config) => {
    if (store.state.auth.auth_token) {
      config.headers.common.Authorization = `Bearer ${store.state.auth.auth_token}`
    } else {
      // app.$toast.error('Session has expired, please log in again')
      // app.$dialog.message.error('Session has expired, please log in again', {
      //   position: 'bottom',
      //   color: 'red',
      // })
      // console.log('Session has expired, please log in again')
      // console.log('You have been redirected from an authentication error error')
      return redirect('/')
    }
  })
}
