import Crypto from 'crypto-js'
import Cookie from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import createPersistedState from 'vuex-persistedstate'

const cookieName = 'myuse'
// const storageKey = 'myuse-storage'
// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || uuidv4()
// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: false, expires: 180 })
export default ({ store }) => {
  // const ls = new SecureLS({ isCompression: false })
  createPersistedState({
    storage: {
      // intercept get item from vuex store
      getItem: (key, value) => {
        // console.log('getting item from localstorage...', key, value)
        const item_to_decrypt = window.localStorage.getItem(key)

        if (item_to_decrypt) {
          // Decrypt the store retrieved from local storage using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(item_to_decrypt, encryptionToken)
          try {
            return JSON.parse(bytes.toString(Crypto.enc.Utf8))
          } catch (e) {
            // The store will be reset if decryption fails.
            // window.localStorage.removeItem(storageKey)
          }
        }
      },
      // intercept set item from vuex store
      setItem: (key, value) => {
        // Encrypt the store using our encryption token stored in cookies.
        const encrypted_value = Crypto.AES.encrypt(
          value.toString(),
          encryptionToken
        ).toString()

        try {

          // set in local storage if remember me is enabled
          if (Cookie.get('remember-me') === '1') {
            // console.log('SETTING IN LOCALSTORAGE', key)
            window.localStorage.setItem(key, encrypted_value)
          } else {
            // window.localStorage.clear()
          }
        } catch (e) {
          // ('could not set persisted state as remmeber me not set.')
        }
      },
      removeItem: (key) => window.localStorage.removeItem(key),
    },
    paths: ['auth', 'navigation'],
  })(store)
}
