export default function ({ $axios, store }) {
  // persist axios token on header after every call
  $axios.onRequest((config) => {
    // attach the given auth token with each user request
    if (store.state.auth.auth_token) {
      config.headers.common.Authorization = `Bearer ${store.state.auth.auth_token}`
    }

    // send the subdomain name with each request, this will be detected by stancl library and assign database
    let current_tenant = window.location.hostname.split('.').shift() || 'ucc'

    // if (current_tenant === 'localhost') {
    current_tenant = 'ucc'
    // } else if (current_tenant.includes()) {
    //   current_tenant = 'ucc'
    // } else if (current_tenant.includes) {

    config.headers.common['X-Tenant'] = current_tenant
  })
}
