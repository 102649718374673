/*
 * User navigation history stored here
 * Cookie and tenancy settings also stored here also stored here
 */

const getDefaultState = () => {
  return {
    current_page: '1-0',
    next_page: null,
    current_module: null,
    page_completed: false,
    progress: 0,
    std_progress: 0,
    navigation_history: { '1-0': null },
    optional_history: {},
    optional_completed: [],
    optional_available: [],
    optional_mode: false,
    notifier_info: null,
    display_notification: false,
    going_back: false,
    last_viewed_page: null,
    next_viewed_page: null,
    menu_items: [],
    module_color: null,
  }
}

// initial state
export const state = getDefaultState()

// check object has key
const containsKey = function (obj, key) {
  try {
    if (Object.keys(obj).includes(key)) {
      return true
    } else {
      return false
    }
  } catch (e) {
    // console.log('contains key has run into an issue', e)
    return false
  }
}

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },

  // set the current page
  setCurrentPage(state, current_page) {
    state.current_page = current_page
  },

  // set the current module if it exists
  setCurrentModule(state, current_module) {
    state.current_module = current_module
  },

  // set the module_color if it exists
  setCurrentModuleColor(state, module_color) {
    state.module_color = module_color
  },

  // set the next page to move to on clicking next
  setNextPage(state, next_page) {
    state.next_page = next_page
  },

  // set the next page used in carousels
  setNextViewedPage(state, next_viewed_page) {
    state.next_viewed_page = next_viewed_page
  },

  // set the last seen page
  setLastViewedPage(state, last_viewed_page) {
    state.last_viewed_page = last_viewed_page
  },

  // set the current page as completed
  setPageCompleted(state, page_completed) {
    state.page_completed = page_completed
  },

  // set the progress bar value for this page
  setPageProgress(state, progress) {
    state.progress = progress || 0 // todo: this is wrong?
    // store the users progress in standard mode todo: this needs to be persisted
    if (state.optional_mode === false) {
      state.std_progress = progress
    }
  },

  // store any answers for this page
  setNavigationHistory(state, item) {
    if (state.optional_mode) {
      state.optional_history[state.current_page] = item
    } else {
      state.navigation_history[state.current_page] = item
    }
  },

  setCurrentModuleName(state, module_name) {
    state.module_name = module_name
  },

  // set the notification info for this page todo: merge with the above mutation
  setNotifierInfo(state, notifier_info) {
    state.notifier_info = notifier_info
  },

  // determine if this user is navigating backwards todo: may need refactoring
  setGoingBack(state, going_back) {
    state.going_back = going_back
  },

  // set the users navigaton history
  setPageHistoryViewed(state, page) {
    if (!containsKey(state.navigation_history, page)) {
      if (state.optional_mode) {
        state.optional_history[page] = ''
      } else {
        state.navigation_history[page] = ''
      }
    }
  },

  // mark optional mode status
  setOptionalModeStatus(state, status) {
    state.optional_mode = status
  },

  // mark optional completed status
  setCompletedOptionalModules(state, optional_completed) {
    state.optional_completed = optional_completed
  },

  // mark optional completed status
  setAvailableOptionalModules(state, optional_available) {
    state.optional_available = optional_available
  },
  // used when loggingin
  resetNavigationHistory(state, navigation_history) {
    // console.log('replacing navigation_history history', navigation_history)
    state.navigation_history = helpers.parseIfString(navigation_history)
  },

  // set whethere notification should be open for this page
  setDisplayNotification(state, display_notification) {
    state.display_notification = display_notification
  },

  // mark optional completed status
  resetOptionalHistory(state, history) {
    state.optional_history =
      helpers.parseIfString(history.optional_history) || []
    state.optional_available =
      helpers.parseIfString(history.optional_available) || []
    state.optional_completed =
      helpers.parseIfString(history.optional_completed) || []
  },

  // set dashboard menu items
  setMenuItems(state, menu) {
    state.menu_items = menu
  },

  // set an optional module as complete todo: thi sneeds testing
  setOptionalAvailable(state, optional) {
    // console.log('setOptionalAvalable', optional)
    state.optional_available = optional
  },
}

export const actions = {
  // reset some state variables when brought back to main page
  resetState({ commit }) {
    commit('RESET_STATE')
  },

  // store any answers for this page
  replaceNavigationHistory({ dispatch, commit }, history) {
    // set the module name
    commit(
      'setCurrentModule',
      history.last_page === 'Review'
        ? 'Review'
        : history.last_page.split('-')[0]
    )

    commit('setCurrentPage', history.last_page)
    dispatch('returnToLastPage')
    commit('resetNavigationHistory', helpers.parseIfString(history.main))
    commit('resetOptionalHistory', history)
    dispatch('setNavigationArrowPages')
    dispatch('processMenuItems')
  },

  // set existing answers for page, if available
  async addToHistory({ commit }, payload) {
    await commit('setPageHistoryViewed', payload)
  },

  // current state settings
  async setCurrentPage({ commit, state, rootGetters, dispatch }, page) {
    commit('setCurrentPage', page)
    // check if this is a new module

    try {
      const moduleNo = page.split('-')[0]
      if (state.current_module !== moduleNo) {
        commit('setCurrentModule', moduleNo)
        dispatch('processMenuItems')
        commit(
          'setCurrentModuleName',
          rootGetters['content/getLabels'].modules[moduleNo]
        )
        await dispatch('storeNavigationHistory')

        // set the nav bar color
        commit('setCurrentModuleColor', rootGetters['content/getLabels'].colors[moduleNo])
      }
    } catch (e) {
        console.log('page does not exist, skipping', e)
    }
  },

  // save the users optional navigation history ()
  async storeOptionalHistory({ state }) {
    // console.log(
    //   'STORING OPTIONAL HISTORY',
    //   state.optional_history,
    //   state.optional_completed,
    //   state.optional_available
    // )
    await this.$axios
      .$post(this.$config.BASE_API + '/activity/commit_optional_history', {
        history: state.optional_history,
        completed: state.optional_completed,
        available: state.optional_available,
      })
      .catch(() => {
        //  console.log(err)
      })
  },

  // save the users navigation history to the server todo: todo: expensive, might be better per module (maybe logout?)
  async storeNavigationHistory({ state }) {
    // console.log('STORING NAV HISTORY', state.navigation_history)
    await this.$axios
      .$post(this.$config.BASE_API + '/activity/commit_navigation_history', {
        navigation_history: JSON.stringify(state.navigation_history),
        last_page: state.current_page,
      })
      .catch(() => {
        //  console.log(err)
      })
  },

  // set an optional module as complete todo: thi sneeds testing
  setOptionalModuleAsCompleted({ dispatch, state, commit }) {
    // console.log(
    //   'optional module marked as competed, optional currently',
    //   state.optional_completed
    // )
    // console.log(
    //   'optional module marked as competed, available currently',
    //   state.optional_available
    // )

    // let optional_hist = helpers.clone(state.optional_completed) || []
    const optional_hist = [...new Set(state.optional_completed || [])] // remove duplicates
    optional_hist.push(state.current_module)
    // let optional_avail = helpers.clone(state.optional_available) || []
    let optional_avail = [...new Set(state.optional_available || [])] // remove duplicates
    optional_avail = optional_avail.filter((e) => e !== state.current_module)
    // console.log('optional module marked as competed', optional_hist)
    dispatch('storeOptionalHistory')
    commit('setCompletedOptionalModules', optional_hist)
    commit('setAvailableOptionalModules', optional_avail)
  },

  // set next page and mark this page completed
  setNextPage({ commit }, page) {
    commit('setNextPage', page)
    commit('setPageCompleted', true)
  },

  // return to standard mode from optional mode
  goToMenu({ commit, state, dispatch }) {
    const keys = Object.keys(state.navigation_history)
    dispatch('processMenuItems')
    dispatch('setCurrentPage', keys[keys.length - 1])
    dispatch('setOptionalModStatus', false)
    commit('setPageProgress', state.std_progress) //  todo this value needs to be persisted
    this.$router.push('/dashboard')
  },

  // determine if this this is optional mode or not
  async setOptionalModStatus({ commit }, optional_mode) {
    if (optional_mode) {
      await this.$ga.event({
        eventCategory: 'Activity',
        eventAction: 'Started optional Mode',
        eventValue: optional_mode,
      })
    }
    commit('setOptionalModeStatus', optional_mode) //  todo this value needs to be persisted
  },

  // bring user to the past page the viewed
  returnToLastPage({ state, rootGetters }) {
    const user = rootGetters['auth/getUser']
    // console.log('returning to last page', state.current_page)
    if (state.current_page && user) {
      this.$router.push({
        path: '/page/' + state.current_page,
      })
    } else {
      this.$router.push({ path: '/' })
    }
  },

  // retrieve next and previous pages for caret
  setNavigationArrowPages({ commit, state }) {
    let keys, nextIndex, lastIndex
    if (state.optional_mode === true) {
      // get all pages within optional mode history
      keys = helpers.getKeysInOrder(state.optional_history)
    } else {
      // get all pages within normal mode history
      keys = helpers.getKeysInOrder(state.navigation_history)
    }

    // if this page is alrady in the history, sset next and previous
    if (keys.includes(state.current_page)) {
      nextIndex = keys.indexOf(state.current_page) + 1
      lastIndex = keys.indexOf(state.current_page) - 1

      commit('setLastViewedPage', keys[lastIndex])
      commit('setNextViewedPage', keys[nextIndex])

      // console.log('Set caret navigation history - ', {
      //   current_page: state.current_page,
      //   last_page: keys[lastIndex],
      //   next_page: keys[nextIndex],
      //   keys,
      // })
    } else {
      // first time on this page
      commit('setLastViewedPage', keys[keys.length - 1])
      commit('setNextViewedPage', undefined)
    }
  },

  processMenuItems({ commit, state, rootGetters }) {
    const content = rootGetters['content/getModuleContent']
    const module_content = []
    const found_completed_modules = []
    const optional_completed = state.optional_completed || []
    const optional_available_state = state.optional_available || []

    // console.log('process menu itesm', optional_completed)

    // find all the completed optional  modules to: requires refactoring
    optional_completed.forEach(function (question) {
      if (!found_completed_modules.includes(question.split('-')[0])) {
        found_completed_modules.push(question)
      }
    })

    // find all the completed modules
    helpers
      .getModulesCompleted(state.navigation_history)
      .forEach(function (question) {
        if (!found_completed_modules.includes(question.split('-')[0])) {
          found_completed_modules.push(question)
        }
      })

    // find the content for all compeltee moduels
    found_completed_modules.forEach(function (qs) {
      const found = content.items.filter(function (item) {
        return item.id === qs
      })

      // set the cuoorect icon for this module
      if (found && found.length > 0) {
        found[0].complete = state.current_module !== found[0].category
        found[0].current = state.current_module === found[0].category
        found[0].icon =
          state.current_module === found[0].category ? 'mdi-check' : ''
        module_content.push(found[0])
      }
    })
    // add optionall modules that are available
    // find content for available optional modules
    let new_optional_available
    if (optional_available_state) {
      new_optional_available = content.optional.filter((item) =>
        optional_available_state.includes(item.category)
      )
    }

    // console.log('new optional avilable is', new_optional_available)
    // settings for optional modules
    if (new_optional_available.length > 0) {
      new_optional_available.forEach(function (module_description) {
        const optonal_helper = helpers.clone(module_description)
        optonal_helper.optional = true
        optonal_helper.finished = true

        if (optional_completed.includes(optonal_helper.category)) {
          optonal_helper.icon = 'mdi-check'
        } else {
          optonal_helper.icon = 'mdi-lightbulb-on'
        }

        optonal_helper.editable = true
        // console.log('MENU: adding optional module', optonal_helper)
        module_content.push(optonal_helper)
      })
    }

    // console.log('final optional available are ', module_content)

    // show blank module if user has more to go
    if (state.current_module !== 'Review') {
      module_content.push(content.extra)
    }
    commit('setMenuItems', module_content)
  },

  // unlock modules to user
  unlockOptionalModules({ commit, dispatch, state }, unlocked) {
    const optional_available = [...new Set(state.optional_available || [])]

    // add to optional available if it doesn't exist
    unlocked.forEach((mod) => {
      // check if this module is already avialle
      if (optional_available.includes(mod)) {
        return
      }
      optional_available.push(mod)
    })

    //  console.log('new modules have been made available', optional_available)

    // set available options
    commit('setOptionalAvailable', optional_available)
    // store the history
    dispatch('storeOptionalHistory')
    // prepare pop up to notify user
    commit('setNotifierInfo', unlocked)
    // show pop up
    commit('setDisplayNotification', true)
    // prepare enw menu items in dashboard
    dispatch('processMenuItems')
  },
}

export const getters = {
  // find the next page to move to
  getNextPage: (state) => {
    return state.next_page
  },

  // find the current page
  getCurrentPage: (state) => {
    // console.log('current page is ', state.current_page)
    return state.current_page
  },

  // get the completed state of the pagw
  getPageCompleted: (state) => {
    return state.page_completed
  },

  // find the current module no
  getCurrentModule: (state) => {
    return state.current_module
  },

  // get the progress value for this page
  getPageProgress: (state) => {
    return state.progress
  },

  // get the progress value for this page
  getLastViewedPage: (state) => {
    return state.last_viewed_page
  },

  // get the progress value for this page
  getNextViewedPage: (state) => {
    return state.next_viewed_page
  },

  // set whethere notification should be open for this page
  getDisplayNotificationStatus: (state) => {
    return state.display_notification
  },

  // get info to dispaly in page notifiers
  getNotifierInfo: (state) => {
    return state.notifier_info
  },

  // set whethere notification should be open for this page
  getGoingBack: (state) => {
    return state.going_back
  },

  // set whethere notification should be open for this page
  getOptionalCompleted: (state) => {
    return state.optional_completed
  },

  // set whethere notification should be open for this page
  getOptionalAvailable: (state) => {
    return state.optional_available
  },

  // get the users navigation history
  getNavigationHistory: (state) => {
    return state.navigation_history
  },

  // retrieve any existing answers to this page
  getPageAnswers: (state) => {
    // for optional mode
    if (state.optional_mode) {
      if (state.optional_history) {
        if (containsKey(state.optional_history, state.current_page)) {
          return state.optional_history[state.current_page] || []
        }
      }
      // for standard mode
    } else if (state.navigation_history) {
      if (containsKey(state.navigation_history, state.current_page)) {
        return state.navigation_history[state.current_page] || []
      }
    }

    return null
  },

  // determine if this this is optional mode or not
  getOptionalModStatus: (state) => {
    return state.optional_mode
  },

  getFinishedModules: (state) => {
    return state.finished_modules
  },
  getMenuItems: (state) => {
    return state.menu_items
  },
  getModuleColor: (state) => {
    return state.module_color || "primary lighten-3"
  }
}

export const helpers = {
  // clone a json object
  clone(data) {
    return JSON.parse(JSON.stringify(data))
  },
  parseIfString(json) {
    if (Array.isArray(json)) {
      return json
    } else {
      try {
        return JSON.parse(json)
      } catch (e) {
        return json
      }
    }
  },
  // arrange navigation keys in correct sequence todo
  getKeysInOrder(json) {
    if (!json) {
      //   console.log('ERROR getting keys:', json)
      return
    }

    const key = Object.keys(json)
    const mykey = key.sort((a, b) =>
      a.localeCompare(b, navigator.languages[0] || navigator.language, {
        numeric: true,
        ignorePunctuation: true,
      })
    )
    return mykey
  },
  getModulesCompleted(navigation_history) {
    const arr = []
    // console.log('inner nav hisory', navigation_history)
    const keys = Object.keys(navigation_history)
    // find all the completed modules to: requires refactoring
    keys.forEach(function (question, index) {
      const module = question.split('-')[0]
      if (!arr.includes(module)) {
        arr.push(module)
      }
    })

    return arr
  },
}
