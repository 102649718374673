/*
 * Generate the appropriate feedback content for users
 * Feedback is based on prior user answers, based on the ~MyUSE Clinical Algorithm~
 */

// default state
const getDefaultState = () => {
  return {
    fb_rules: null,
    fb_items: null,
    fb_content: null,
  }
}

// initial state
export const state = getDefaultState()

// helper to check key
const containsKey = function (obj, key) {
  try {
    if (Object.keys(obj).includes(key)) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

// todo: review feedback display methodolgy, dependancy injection
export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },

  // set the feedback items to be shown
  setFeedbackItems(state, fb_items) {
    state.fb_items = fb_items
  },

  // set rules to display feedback
  setFeedbackRules(state, rules) {
    state.fb_rules = rules
  },

  // set custom feedback to be shown
  setFeedbackContent(state, fb_content) {
    state.fb_content = fb_content
  },
}

export const actions = {
  // delete the current navigation state
  resetState({ commit }) {
    commit('RESET_STATE')
  },

  // generate feedback from feedback component on page (called from component)
  processFeedback({ state, dispatch, commit }, content) {
    // set the correct content to page
    commit('setFeedbackContent', content)
    // if page rule have an associated action, proceed
    if (state.fb_rules && containsKey(state.fb_rules, 'key')) {
      dispatch(state.fb_rules.key)
    }
  },

  // process feedback as 1:1 to answers
  processGenericFeedback({ commit, getters }, payload) {
    const feedback_items = []
    try {
      payload.answers.forEach((answer) => {
        const feedback = getters.getFeedbackItem(answer, payload.content)
        if (answer.other) feedback.other = answer.other
        feedback_items.push(feedback)
      })
    } catch (e) {
      // console.log('processGenericFeedback Error', e)
    }
    commit('setFeedbackItems', feedback_items)
  },

  // page 1-4
  processDastScore({ commit, state }) {
    const content = state.fb_content.items
    this.$axios
      .$get(this.$config.BASE_API + '/processing/process_dast_score')
      .then((response) => {
        commit('setFeedbackItems', [content[response.content_no]])
      })
      .catch((e) => {
        // console.log(e)
      })
  },

  // page 1-5 (could go server side)
  processDASTFeedback({ commit, rootGetters, state }) {
    const feedback_items = []
    const content = state.fb_content.items

    // List of answers requiring feedback (Yes=0, No=1)
    const feedback_answers = [0, 1, 0, 0, 0, 0, 0, 0, 0]

    try {
      // add first feedback item by default
      feedback_items.push(content[0])
      // Loop through answers and collect feedback
      rootGetters['profile/getDASTAnswers'].forEach((answer, index) => {
        // check if selected yes
        if (answer === feedback_answers[index]) {
          // reorganise so content on suicide is at top
          if (index === 3) {
            feedback_items.unshift(content[index + 1])
          } else {
            feedback_items.push(content[index + 1])
          }
        }
      })
    } catch (e) {
      // console.log('Error: processDASTFeedback', e)
    }
    commit('setFeedbackItems', feedback_items)
  },

  // page 1-4A
  processQuizFeedback({ state, commit }) {
    const content = state.fb_content.items
    this.$axios
      .$get(this.$config.BASE_API + '/processing/process_quiz_score')
      .then((response) => {
        commit('setFeedbackItems', [content[response.content_no]])
      })
      .catch((e) => {
        // console.log(e)
      })
  },

  // page 1-7
  processExperiences({ commit, rootGetters, state }) {
    const content = state.fb_content.items
    this.$axios
      .$post(this.$config.BASE_API + '/processing/process_posnegs', {
        positives: rootGetters['profile/getPositives'].length,
        negatives: rootGetters['profile/getNegatives'].length,
      })
      .then((response) => {
        commit('setFeedbackItems', [content[response.content_no]])
      })
      .catch((e) => {
        // console.log(e)
      })
  },

  // page 1-11C
  processConfidence({ state, commit }) {
    const content = state.fb_content.items
    this.$axios
      .$get(this.$config.BASE_API + '/processing/process_confidence')
      .then((response) => {
        // console.log('got feedback...', response)
        commit('setFeedbackItems', [content[response.content_no]])
      })
      .catch((e) => {
        // console.log(e)
      })
  },

  // page 1-14 (can be done on server)
  processDescriptiveNorms({ rootGetters, commit }) {
    const scores = rootGetters['profile/getDescriptiveNorms']
    const feedback_items = []
    const answers = [68, 34]
    const errorMargin = 5

    // check if the answer is within error margin TODO this could be abstracted elsewhere
    for (let i = 0; i < answers.length; i++) {
      const answerType =
        scores[i] <= answers[i] - errorMargin ||
        scores[i] >= answers[i] + errorMargin
          ? 0
          : 1

      feedback_items.push({
        answerType,
        scores: [answers[i], scores[i]],
      })
    }

    commit('setFeedbackItems', feedback_items)
    // store on the server
    helpers.storeScoreOnServer(scores.toString(), 9, this.$axios, this.$config)
  },

  // page 1-15, using answers from 1-14
  processInjunctiveNorms({ state, rootGetters, commit, dispatch, getters }) {
    const answers = rootGetters['profile/getInjunctiveNorms']
    const user_type = rootGetters['profile/getUserType']
    const content = state.fb_content.items
    const feedback_items = []
    // console.log('processInjunctiveNorms', answers)
    // redirect for non-users
    let next_page = '2-0'

    // skips page 2-1, 2-2
    if (user_type === 1) {
      next_page = '2-0A'
    }

    // patterns for matching answers to overall feedback
    const patterns = [
      { pattern: [0, 4, 7], overall: 0 }, // Approve avoiding drugs
      { pattern: [1, 3, 6], overall: 1 }, // Approve taking drugs
      { pattern: [2, 5, 8], overall: 2 }, // Mixed views about drugs
    ]

    // check if answers match pattern
    function matchPattern(pattern, answers) {
      return answers.every((answer, index) => {
        return answer.data_id === 'injunctive-norm-' + pattern[index]
      })
    }

    // get overall feedback, default to 'Mixed'
    function getOverallFeedback() {
      let overall = content[11]
      patterns.forEach((item, index) => {
        if (matchPattern(item.pattern, answers)) {
          const id = 'injunctive-norm-overall-' + item.overall
          overall = getters.getFeedbackItem({ data_id: id }, content)
        }
      })
      return overall
    }

    // console.log('ANSWERS ', answers)

    try {
      // get feedback for each answer
      answers.forEach((item) => {
        const feedback = getters.getFeedbackItem(
          { data_id: item.data_id },
          content
        )
        feedback_items.push(feedback)
      })
      // get overall feedback
      const overall = getOverallFeedback()
      feedback_items.push(overall)
    } catch (e) {
      // console.log('processInjunctiveNorms Error: ', e)
    }

    // remove any duplicates
    const arrFiltered = feedback_items.filter((el) => {
      return el != null && el !== ''
    })

    commit('setFeedbackItems', arrFiltered)
    dispatch('navigation/setNextPage', next_page, {
      root: true,
    })
  },
  // page 2-2 (can be moved server side)
  processRelationship({ state, rootGetters, commit }) {
    const scores = rootGetters['profile/getRelationshipScores']

    // console.log('relationship scores', scores)

    const content = state.fb_content.items
    const feedback_items = []
    const total = scores.reduce((a, b) => a + b, 0)

    // find the correct feedback from values
    function getFeedback(answer, contentA, contentB) {
      return answer >= 5 ? contentA : contentB
    }

    try {
      const feedbackA = getFeedback(scores[0], content[0], content[1])
      const feedbackB = getFeedback(scores[1], content[2], content[3])
      const feedbackC = getFeedback(scores[2], content[4], content[5])
      feedback_items.push(feedbackA, feedbackB, feedbackC)

      if (total > 5) {
        feedback_items.push(content[6])
      }
    } catch (e) {
      // console.log('Error: processRelationship', e)
    }
    commit('setFeedbackItems', feedback_items)
  },

  // page 2-4
  processBehaviourFeedback({ state, commit }) {
    this.$axios
      .$get(this.$config.BASE_API + '/processing/process_behaviour_feedback')
      .then((response) => {
        // console.log('got feedback...', response)
        commit('setFeedbackItems', [
          state.fb_content.items[response.content_no],
        ])
      })
      .catch((e) => {
        // console.log(e)
      })
  },

  // page 2-6
  processACT({ state, commit }) {
    this.$axios
      .$get(this.$config.BASE_API + '/processing/process_behavioural_awareness')
      .then((response) => {
        commit('setFeedbackItems', [
          state.fb_content.items[response.content_no],
        ])
      })
      .catch((e) => {
        // console.log(e)
      })
  },

  // page 2-8 / 5A-1
  processDecisionMaking({ state, commit }) {
    this.$axios
      .$get(this.$config.BASE_API + '/processing/process_decision_making_score')
      .then((response) => {
        // console.log('got feedback...', response)
        commit('setFeedbackItems', [
          state.fb_content.items[response.content_no],
        ])
      })
      .catch((e) => {
        // console.log(e)
      })
  },

  // Page 2-9
  processFullyPresentMetaphor({ rootGetters, dispatch }) {
    const highACTScore =
      rootGetters['profile/getBehaviouralAwarenessScore'] >= 17 // or act score
    const drugUser = rootGetters['profile/getUserType'] !== 1
    const highDecisionScore =
      rootGetters['profile/getDecisionMakingScore'] >= 32
    const lowLifeValuesScore = rootGetters['profile/getLifeValuesScore'] <= 10 // this is progress score 2-3

    // console.log(
    //   'highACTScore:',
    //   highACTScore,
    //   rootGetters['profile/getBehaviouralAwarenessScore'],
    //   rootGetters['profile/getUserType']
    // )
    // console.log('drugUser:', drugUser, rootGetters['profile/getUserType'])
    // console.log(
    //   'highDecisionScore:',
    //   highDecisionScore,
    //   rootGetters['profile/getDecisionMakingScore']
    // )
    // console.log(
    //   'lowLifeValuesScore:',
    //   lowLifeValuesScore,
    //   rootGetters['profile/getLifeValuesScore']
    // )

    if (lowLifeValuesScore) {
      // console.log(
      //   'low life values score found, setting net page to 3',
      //   lowLifeValuesScore
      // )
      dispatch('setNextPage', '3-0')
    } else {
      // console.log(
      //   'low life values score nNOT found, UNLOCKING 3',
      //   lowLifeValuesScore
      // )

      // 3 is now an optional module
      const unlocked = ['3']

      // unlock subsequent modules after the above
      const condition = highACTScore && highDecisionScore
      if (drugUser) {
        dispatch('setNextPage', condition ? '5-0' : '4-0')

        // let the user reach this module in optional modules
        if (!condition) {
          // console.log(
          //   'highACT AND highDecisionScore NOT found. UNLOCKING 4 ',
          //   highACTScore,
          //   highDecisionScore
          // )
          unlocked.push('4')
        }
      } else {
        dispatch('setNextPage', condition ? 'Review' : '4A-0')

        // let the user reach this module in optional modules
        if (!condition) {
          // console.log(
          //   'highACT AND highDecisionScore NOT found.UNLOCKING 4a ',
          //   highACTScore,
          //   highDecisionScore
          // )
          unlocked.push('4A')
        }
      }

      // console.log('unlocked', unlocked)
      dispatch('navigation/unlockOptionalModules', unlocked, {
        root: true,
      })
    }
  },

  // page 2C-2
  processNeedsFeedback({ state, rootGetters, dispatch }) {
    dispatch('processGenericFeedback', {
      answers: rootGetters['profile/getNeeds'],
      content: state.fb_content.items,
    })
  },

  // page 2C-4
  processHelpFeedback({ state, rootGetters, dispatch }) {
    dispatch('processGenericFeedback', {
      answers: rootGetters['profile/getHelps'],
      content: state.fb_content.items,
    })
  },

  // 4A-3
  processInfluencesFeedback({ state, rootGetters, dispatch }) {
    dispatch('processGenericFeedback', {
      answers: rootGetters['profile/getInfluences'],
      content: state.fb_content.items,
    })
  },

  // 4-3
  processReasonsForUse({ state, rootGetters, dispatch }) {
    dispatch('processGenericFeedback', {
      answers: rootGetters['profile/getReasonsForUse'],
      content: state.fb_content.items,
    })
  },

  // 4-8 is this correct? MAJTODO
  processMyDecisions({ state, rootGetters, dispatch }) {
    dispatch('processGenericFeedback', {
      answers: rootGetters['profile/getNegatives'],
      content: state.fb_content.items,
    })
  },

  // 4-9 decide which page to navigate to next
  processMyInfluences({ rootGetters, dispatch }) {
    // set the next page (back to dashboard if optonal)
    dispatch(
      'setNextPage',
      rootGetters['navigation/getOptionalModStatus'] ? 'dashboard' : '5-0'
    )
  },

  // 5.2 (only store data ids - can be done)
  processHRFeedback({ state, rootGetters, commit, getters }) {
    // console.log(
    //   'THIS IS THE HR FEEDBACK',
    //   rootGetters['profile/getHRPractices']
    // )
    const feedback = []
    try {
      rootGetters['profile/getHRPractices'].forEach((answer) => {
        const item = getters.getFeedbackItem(answer, state.fb_content.items)
        if (answer.data_id === 'hr-practice-7') {
          item.recall = ['InjunctiveNorms']
        } else if (answer.data_id === 'hr-practice-8') {
          item.recall = ['ReasonsForUse', 'DarraghHooks']
        }
        feedback.push(item)
      })
    } catch (e) {
      // console.log('processHRFeedback Error', e)
    }
    commit('setFeedbackItems', feedback)
  },

  //  5A-4 (only store data ids - can be done)
  processSkillsFeedback({ state, rootGetters, commit, getters }) {
    const feedback = []
    try {
      rootGetters['profile/getSkills'].forEach((answer) => {
        const item = getters.getFeedbackItem(answer, state.fb_content.items)
        if (answer.data_id === 'skill-7') {
          item.recall = ['InjunctiveNorms']
        } else if (answer.data_id === 'skill-8') {
          item.recall = ['ReasonsForUse', 'DarraghHooks']
        }
        feedback.push(item)
      })
    } catch (e) {
      // console.log('processHRFeedback Error', e)
    }
    commit('setFeedbackItems', feedback)
  },

  // 3-8
  processValuesFeedback({ rootGetters, dispatch }) {
    const highDecisionScore = rootGetters['profile/getDecisionMakingScore'] > 32
    const highACTScore =
      rootGetters['profile/getBehaviouralAwarenessScore'] >= 17
    const drugUser =
      rootGetters['profile/getUserType'] !== 1 &&
      rootGetters['profile/getUserType'] !== '1'

    // console.log('RUG USER?', drugUser)
    // console.log('RUG USER?', rootGetters['profile/getUserType'] !== 1)

    // similar to the previous condition, but with OR operator
    if (highACTScore || highDecisionScore) {
      // console.log(
      //   'This is a highACT score OR a highdecision score',
      //   highACTScore,
      //   rootGetters['profile/getBehaviouralAwarenessScore'],
      //   highDecisionScore,
      //   rootGetters['profile/getDecisionMakingScore']
      // )
      // console.log('next page is', drugUser ? '4-0' : '4A-0')
      dispatch('setNextPage', drugUser ? '4-0' : '4A-0')
    } else {
      // console.log(
      //   'This is NOT a highACT score OR a highdecision score',
      //   highACTScore,
      //   rootGetters['profile/getBehaviouralAwarenessScore'],
      //   highDecisionScore,
      //   rootGetters['profile/getDecisionMakingScore']
      // )
      dispatch('setNextPage', drugUser ? '5-0' : '5A-0')
      // console.log('next page is', drugUser ? '4-0' : '4A-0')
      // console.log('unlocking', drugUser ? ['4'] : ['4A'])
      dispatch('navigation/unlockOptionalModules', drugUser ? ['4'] : ['4A'], {
        root: true,
      })
    }
  },

  // set next page
  setNextPage({ dispatch }, next_page) {
    dispatch('navigation/setNextPage', next_page, {
      root: true,
    })
  },
}

export const getters = {
  // get the current feedback items
  getFeedbackItems(state) {
    return state.fb_items
  },

  // retrieve correct feedback item from markdown (todo: review naming convention)
  getFeedbackItem: () => (answer, content) => {
    return content.find((item) => item.data_id === answer.data_id)
  },
}

export const helpers = {
  clone(data) {
    return JSON.parse(JSON.stringify(data))
  },
  getItem(answer, content) {
    return content.find((item) => item.data_id === answer.data_id)
  },
  async storeActivityOnServer(item, type, $axios, $config) {
    await $axios.$post($config.BASE_API + '/activity/commit_activity', {
      value: JSON.stringify(item),
      type,
    })
  },
  async storeScoreOnServer(value, type, $axios, $config) {
    await $axios.$post($config.BASE_API + '/activity/commit_score', {
      value,
      type,
    })
  },
}
