// ** User Profile Store ** //
// sets and gets profile
import data_id_content from '../content/general/choice-group-items.json'

const root = {
  root: true,
}

const getDefaultState = () => {
  return {
    positivesList: null,
    negativesList: null,
    dast_score: 0, // set as non-user by default
    dastList: [],
    quiz_score: null,
    user_type: null,
    primary_drug: null,
    personalGoals: [],
    injunctiveNorms: [],
    descriptiveNorms: [],
    relationship_scores: [],
    behavioural_score: null,
    confidence_score: null,
    life_values_score: null,
    decision_making_score: null,
    smart: [],
    influences: null,
    needs: null,
    skills: null,
    hooks: null,
    helps: null,
    values: null,
    values_scores: null,
    reasons_for_use: null,
    hr_practices: null,
    hr_commitment: null,
    other_influences: null
  }
}

// initial state
export const state = getDefaultState()

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },

  setDASTAnswers(state, dastList) {
    state.dastList = dastList
  },

  setDASTScore(state, score) {
    state.dast_score = parseInt(score)
  },

  // used as part of the non-user part
  async setUserType(state, type) {
    state.user_type = parseInt(type)
    // if (type && type === 1) {
    await this.$axios.$post(
      this.$config.BASE_API + '/activity/commit_user_type',
      {
        user_type: state.user_type,
      }
    )

    // }
  },

  // 1-2 user only - drugs which the user has taken
  // we will send the entire string here, as it will be matched server side TODO:
  async setSelectedDrugList(state, selected) {
    // if (!state.primary_drug) {

    // do not store again while renagivating
    if (!state.primary_drug && selected.primary_drug) {
      await this.$axios.$post(
        this.$config.BASE_API + '/activity/commit_selected_drugs',
        {
          selection: selected.selected_drugs
            .map((a) => a.backend_id)
            .toString(),
          primary_drug: selected.primary_drug,
        }
      )
    }

    // state.primary_drug = helpers.clone(selected.primary_drug)
    this.commit('profile/setPrimaryDrug', selected.primary_drug)
  },

  // 1-6 (these are stored in  navigation string)
  setPosNegs(state, posnegs) {
    // if (!state.negativesList || state.positivesList) {
    const negatives = posnegs.filter((obj) => {
      return obj.data_id.includes('negative')
    })

    const positives = posnegs.filter((obj) => {
      return obj.data_id.includes('positive')
    })

    helpers.storeActivityOnServer(positives, 0, this.$axios)
    helpers.storeActivityOnServer(negatives, 3, this.$axios)

    negatives.stored = true
    positives.stored = true

    // we store the data-id and the string here
    state.negativesList = negatives
    state.positivesList = positives

    // helpers.storeActivityOnServer(posnegs, 3, this.$axios)
    // }
  },

  // 1-6
  setPositives(state, positives) {
    // helpers.storeActivityOnServer(positives, 0, this.$axios)
    positives.stored = true // mark as stored
    state.positivesList = positives
  },

  // 1-6
  setNegatives(state, negatives) {
    // helpers.storeActivityOnServer(negatives, 3, this.$axios)
    negatives.stored = true // mark as stored
    state.negativesList = negatives
  },

  // 1-6 user only - primary drug choice
  setPrimaryDrug(state, primary_drug) {
    state.primary_drug = primary_drug
  },

  // 1-3 Non user only, score
  setQuizScore(state, quiz_score) {
    if (!state.quiz_score) {
      helpers.storeScoreOnServer(quiz_score, 2, this.$axios,this.$config)
    }
    state.quiz_score = parseInt(quiz_score)
  },

  // 1-11
  setPersonalGoals(state, value) {
    // console.log('SETTING GOALS...',value)
    // ensure array is the same format returning from filter
    try {
      value = value.filter(function (el) {
        return el != null && !Array.isArray(el) && el.length>0
      })
    } catch (e) {
      value = [value]
    }

    this.$axios.$post(this.$config.BASE_API + '/activity/commit_activity', {
      value: JSON.stringify(value),
      type:2,
    })
    state.personalGoals = value
  },

  // 1-12
  setDescriptiveNorms(state, descriptive_norms) {
    // if (!state.descriptiveNorms) {
    // // we drop the data-ids at this point
    // const answers = norms.map((a) => a.answer)
    // console.log(
    //   'setting desriptive norms - why arent these saved???',
    //   helpers.clone(descriptive_norms)
    // )
    state.descriptiveNorms = helpers.clone(descriptive_norms)
    // }
  },

  // 1-13
  setInjunctiveNorms(state, injunctive_norms) {
    if (!state.injunctiveNorms) {
      helpers.storeActivityOnServer(injunctive_norms.toString(), 6, this.$axios)
    }

    injunctive_norms.stored = true // mark as stored
    state.injunctiveNorms = injunctive_norms
  },

  // 2-1
  setRelationshipScore(state, scores) {
  //  console.log('setting relationship scores', scores)
    if (!state.relationship_scores) {
      helpers.storeScoreOnServer(scores.toString(), 6, this.$axios,this.$config)
    }
    state.relationship_scores = scores // todo: add scoretypes added to variable, if exists dont proceed
  },

  // 2-5
  setBehaviouralAwarenessScore(state, score) {
    if (!state.behavioural_score) {
      helpers.storeScoreOnServer(score, 5, this.$axios,this.$config)
    }
    state.behavioural_score = score
    // console.log('setBehaviouralAwarenesscision making', score, score)
  },

  // 2-7
  setDecisionMakingScore(state, score) {
    if (!state.decision_making_score) {
      helpers.storeScoreOnServer(score, 3, this.$axios,this.$config)
    }
    // console.log('setting final decision making', score)
    state.decision_making_score = score
  },

  // 1-10C
  setConfidenceToChangeScore(state, confidence_score) {
    if (!state.confidence_score) {
      helpers.storeScoreOnServer(confidence_score, 7, this.$axios,this.$config)
    }

    state.confidence_score = helpers.clone(confidence_score)
  },

  // 4A-3 and 4-6
  setInfluences(state, influences) {
    if (!state.influences) {
      helpers.storeActivityOnServer(influences, 5, this.$axios,this.$config)
    }
    state.influences = influences
  },

  // 2-4 (TODO: scoring function takes place in component)
  setLifeValuesScore(state, score) {
    if (!state.life_values_score) {
      helpers.storeScoreOnServer(score, 4, this.$axios,this.$config)
    }
    state.life_values_score = score
  },

  // 3-2 (here we save an array of chosen strings - future proof content)
  setValues(state, values) {
    helpers.storeBlobActivityOnServer(values, 9, this.$axios,this.$config)
    state.values = values
  },

  // 3-3 rate your values
   setValueScores(state, values_scores) {
    helpers.storeBlobActivityOnServer(values_scores, 17, this.$axios,this.$config)
    state.values_scores =  values_scores
  },

  // 2C-2 (stored within the navigation string only - can denormalise as needed)
  setNeeds(state, needs) {
    if (!state.needs) {
      helpers.storeActivityOnServer(needs, 11, this.$axios,this.$config)
    }
    state.needs = needs
  },

  // 2C-1 (stored within the navigation string only - can denormalise as needed)
  setHelps(state, helps) {
    if (!state.helps) {
      helpers.storeActivityOnServer(helps, 12, this.$axios,this.$config)
    }
    state.helps = helps
  },

  // 5-21 (stored within the navigation string only - can denormalise as needed)
  setHRPractices(state, hr_practices) {
    if (!state.hr_practices) {
      helpers.storeActivityOnServer(hr_practices, 12, this.$axios,this.$config)
    }
    state.hr_practices = hr_practices
  },

  // 5-3 (stored within the navigation string only - can denormalise as needed)
  setHRCommitment(state, hr_commitment) {
    // console.log('set HR setHRCommitment', hr_commitment)
    if (!state.hr_commitment) {
      helpers.storeScoreOnServer(hr_commitment, 8, this.$axios,this.$config)
    }
    state.hr_commitment = hr_commitment
  },

  // 5A-3  (stored within the navigation string only - can denormalise as needed)
  setSkills(state, skills) {
    //  console.log('set HR skills', skills)
    if (!state.skills) {
      helpers.storeActivityOnServer(skills, 15, this.$axios,this.$config)
    }
    state.skills = skills
  },

  // 4-2
  setReasonsForUse(state, reasons_for_use) {
    // console.log('set HR reasons_for_use', reasons_for_use)
    if (!state.reasons_for_use) {
      helpers.storeActivityOnServer(reasons_for_use, 10, this.$axios,this.$config)
    }
    state.reasons_for_use = reasons_for_use
  },

  // 4-6
  setOtherInfluences(state, other_influences) {
    // console.log('set other_influences', other_influences)
    if (!state.other_influences) {
      helpers.storeStringOnServer(other_influences, 16, this.$axios,this.$config) // special enpoint for free-text
    }
    state.other_influences = other_influences
  },

  // 4-5
  setDarraghHooks(state, hooks) {
    // console.log('set hooks', hooks)
    if (!state.hooks) {
      helpers.storeActivityOnServer(hooks, 7, this.$axios,this.$config)
    }
    state.hooks = hooks
  },

  // 3-7
  setSMART(state, smart) {
    // console.log('setting smart goals ', smart)
    // console.log('setting smart goals current ', state.smart, state.smart.length)

    if (
      (Array.isArray(state.smart) && state.smart.length === 0) ||
      (typeof state.smart === 'string' && state.smart.length === 2)
    ) {
      this.$axios.$post(this.$config.BASE_API + '/activity/commit_activity', {
        value: JSON.stringify(smart),
        type: 4,
      })
    }
    state.smart = helpers.clone(smart)
  },
}

export const actions = {
  // reset some state variables when brought back to main page
  resetState({ commit }) {
    commit('RESET_STATE')
  },

  // set the user type based on DAST10
  async setDAST10({ commit, dispatch }, dastList) {
    await this.$axios
      .$post(this.$config.BASE_API + '/activity/commit_dast_responses', {
        dast_answers: dastList,
      })
      .then((response) => {
        commit('setUserType', response.user_type)
        commit('setDASTScore', response.score)
        dispatch('navigation/storeNavigationHistory', {}, root)
      })

    // todo do i need this?
    commit('setDASTAnswers', dastList)

    // set page completed and allow continue
    await commit('navigation/setPageCompleted', true, root)
  },

  // recall a users profile from login //recall scores and activities to be reviewed
  recallProfile({ commit, dispatch }, profile) {
    // console.log('profile recall!', profile)

    // recall this users scores
    profile.scores.forEach(function (item) {
      // console.log('recalling  scores...', {
      //   name: 'set' + item.vuex_type[0],
      //   value: item.value,
      //   type: item.score_type,
      // })

      if (item.value) {
        commit('set' + item.vuex_type[0], helpers.parseStrOrNum(item.value))
      }
    })

    // recall user activities
    profile.activities.forEach(function (item) {
      // rehydrate the necessary text for this users stored data points
      let item_value = item.value
      if (
        item.activity_type !== 2 && // personalGoal
        item.activity_type !== 4 && // SMART
        item.activity_type !== 16 && //
        item.activity_type !== 17 && //
        item.activity_type !== 9 && // Values?
        item.activity_type !== 12 // helps
      ) {
        item_value = helpers.rehydrateData(item.value, item.activity_type)
        item_value.login = true
      }

      if (item.activity_type === 4) {
        item_value = item_value.replace(/\\/g, '')
        item_value = helpers.parseIfString(item_value)

        try {
          item_value = item_value.split('[')[1]
          item_value = item_value.slice(0, item_value.indexOf(']'))
          item_value = JSON.parse(item_value)
        } catch (e) {
          // console.log(e)
        }
      }

      if (item.activity_type === 17 ||item.activity_type === 9||item.activity_type ===12||item.activity_type ===2 ) {

        // console.log('recallin2...'+item.vuex_type[0], {
        //   name: 'set' + item.vuex_type[0],
        //   value: item_value,
        //   type: item.activity_type,
        // })

        item_value = item_value.replace(/\\/g, '')
        item_value = helpers.parseIfString(item_value)

        try {
          commit('set' + item.vuex_type[0], item_value)
          return
        } catch (e) {
          console.log(e)
        }
      }
      else {

        // console.log('recallin1...'+item.vuex_type[0], {
        //   name: 'set' + item.vuex_type[0],
        //   value: item_value,
        //   type: item.activity_type,
        // })

        try {
          console.log('shuuld commit - ',item.vuex_type[0], item_value)
          commit('set' + item.vuex_type[0], item_value)
        } catch (e) {
        //  console.log('ERROR recalling activty', e)
        }
      }
    })


    // set the user type and primary drug
    commit('setPrimaryDrug', profile.primary_drug)
    commit('setUserType', profile.user_type)
    const main_hostory = JSON.parse(profile.navigation_history)

    // recall user navigation history
    dispatch(
      'navigation/replaceNavigationHistory',
      {
        main: main_hostory.main,
        optional_history: main_hostory.optional_history,
        optional_completed: main_hostory.optional_completed,
        optional_available: main_hostory.optional_available,
        last_page: profile.progress,
      },
      {
        root: true,
      }
    )
  },
}

export const getters = {
  getUserType(state) {
    return state.user_type
  },
  getUserTypeName(state) {
    if (state.dast_score >= 9 && state.dast_score <= 10) {
      return 'Severe'
    } else if (state.dast_score >= 6 && state.dast_score <= 8) {
      return 'Substantial'
    } else if (state.dast_score >= 3 && state.dast_score <= 5) {
      return 'Moderate'
    } else if (state.dast_score >= 1 && state.dast_score <= 2) {
      return 'Low'
    } else if (state.dast_score === 0) {
      return 'Non-User'
    }
  },

  getPrimaryDrug(state) {
    return state.primary_drug
  },
  getDASTScore(state) {
    return state.dast_score
  },
  getDASTAnswers(state) {
    return state.dastList
  },
  getQuizScore(state) {
    return state.quiz_score
  },
  getPosNegs(state) {
    return { positives: state.positivesList, negatives: state.negativesList }
  },
  getPositives(state) {
    return state.positivesList || []
  },
  getNegatives(state) {
    return state.negativesList || []
  },
  getDescriptiveNorms(state) {
    return state.descriptiveNorms
  },
  getInjunctiveNorms(state) {
    return state.injunctiveNorms
  },
  getConfidenceToChangeScore(state) {
    return state.confidence_score
  },
  getRelationshipScores(state) {
    return state.relationship_scores
  },
  getPersonalGoals(state) {
    return state.personalGoals
  },
  getLifeValuesScore(state) {
    return state.life_values_score
  },
  // 3-2
  getValues(state) {
    return state.values
  },
  // 3-3
  getValueScores(state) {
    const values_data_copy = helpers.clone(state.values_scores)
    const final = []
    state.values.forEach((value, index) => {
      final.push({
        text: value,
        scores: values_data_copy[index].scores,
      })
    })
    return final
  },
  getBehaviouralAwarenessScore(state) {
    return state.behavioural_score
  },
  getInfluences(state) {
    return state.influences
  },
  // 4-6
  getOtherInfluences(state) {
    // console.log('GETTING OTHER INFLUENCES', state.other_influences) // aslo known as other reasons for use
    return state.other_influences
  },
  getNeeds(state) {
    return state.needs
  },
  getDecisionMakingScore(state) {
    return state.decision_making_score
  },
  getHRPractices(state) {
    return state.hr_practices
  },
  getHelps(state) {
    return state.helps
  },
  getSkills(state) {
    return state.skills
  },
  getReasonsForUse(state) {
    return state.reasons_for_use
  },
  getDarraghHooks(state) {
    return state.hooks
  },
  // 3-7 todo revisit
  getSMART(state) {
    return helpers.parseIfString(state.smart)
  },
}

export const helpers = {
  // rehydrates data-ids so we do not have to store content in backend
  rehydrateData(data, activity_type_no) {
    // console.log('IN HELPER', data, activity_type_no)
    const newdata = JSON.parse(data)
    const content = data_id_content[activity_type_no]
    const data_ids = newdata.map((item, index) => {
      // console.log('item', item)
      const prefix = data_id_content[activity_type_no].prefix
      // console.log('iprefixtem', prefix)
      // console.log('activity_type_no', activity_type_no)
      return prefix + item
    })

    // console.log('data_ids', data_ids)

    const final_rehydrated = []
    // recall this users scores
    data_ids.forEach(function (item) {
      // console.log('checking data id...', item)
      const found = content.content.find((el) => el.data_id === item)
      final_rehydrated.push(found)
    })
    // console.log('FINAL REHYDRATED', final_rehydrated)

    return final_rehydrated
  },
  clone(data) {
    return JSON.parse(JSON.stringify(data))
  },
  parseIfString(json) {
    if (Array.isArray(json)) {
      return json
    } else {
      try {
        return JSON.parse(json)
      } catch (e) {
        // console.log('ERROR recalling score, returing as normal', e)
        return json
      }
    }
  },
  parseStrOrNum(item) {
    if (typeof item === 'number') {
      return item
    } else {
      try {
        return JSON.parse(item)
      } catch (e) {
        // console.log('WARNING recalling score, returing as normal', item)
        return item
      }
    }
  },
  getItem(answer, content) {
    return content.find((item) => item.data_id === answer.data_id)
  },
  stripDataID(data) {
    const stripped = data.reduce((item) => item.data_id)

    return stripped
  },


  async storeBlobActivityOnServer(item, type, $axios, $config) {
    await $axios.$post($config.BASE_API + '/activity/commit_activity', {
      value: JSON.stringify(item),
      type,
    })
 },

  async storeActivityOnServer(item, type, $axios,$config) {
    //  console.log('trying to store activity on server-', item, type)
    try {
      let only_ids = item.map((x) => x.data_id)
      only_ids = only_ids.filter((n) => n)

      const value = only_ids.map((item) => {
        return item.substring(item.lastIndexOf('-') + 1)
      })
      await $axios.$post($config.BASE_API + '/activity/commit_activity', {
        value: JSON.stringify(value),
        type,
      })

    }
    catch(e){
     // console.log('cancelled: attempt to store login param.')
    }

  },
  async storeScoreOnServer(value, type, $axios,$config) {
    await $axios.$post($config.BASE_API + '/activity/commit_score', {
      value: value.toString(),
      type,
    })
  },
  storeStringOnServer(value, type, $axios,$config) {
    $axios.$post($config.BASE_API + '/activity/commit_activity', {
      value: value.toString(),
      type,
    })
  },
}
