
export default {
  name: 'Error',
  transition: 'myuse-fade',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  methods: {
    async loginUser() {
      try {
        await this.$store
          .dispatch('auth/login_user', {
            password: this.user.password,
          })
          .then((response) => {})
          .catch(() => {
            this.$store.dispatch('auth/logoutUser')
          })
      } catch (e) {
        this.$store.dispatch('auth/logoutUser')
      }
    },
  },
}
