/*
 * User navigation history stored here
 * Cookie and tenancy settings also stored here also stored here
 */

const getDefaultState = () => {
  return {
    navigation_history: {},
    is_final_page: false,
  }
}

// initial state
export const state = getDefaultState()

// check object has key
// const containsKey = (obj, key) => Object.keys(obj).includes(key)
// TODO: this must be checked
const containsKey = function (obj, key) {
  try {
    if (Object.keys(obj).includes(key)) {
      return true
    } else {
      return false
    }
  } catch (e) {
    // console.log('contains key has run into an issue', e)
    return false
  }
}

// score comparators
const compare_equal = (num, compare) => num === compare
const compare_greater = (num, compare) => num >= compare
const compare_less = (num, compare) => num < compare

export const mutations = {
  // todo: remove processors after logout
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },

  // set the decision tree for this user
  SET_DECISION_TREE(state, tree) {
    try {
      state.SELECTED_DECISION_TREE = JSON.parse(tree)
    } catch (ex) {
      //   console.log('ERROR COULD NOT PARSE TREE')
    }
  },

  // set the Batch ID
  setDistributionType(state, batch_id) {
    state.batch_id = batch_id
  },

  // set any page rules (from decision tree JSON)
  setPageRules(state, rules) {
    state.rules = rules
  },

  // retireve any ules for this page
  setIsFinalPage(state, is_final) {
    state.is_final_page = is_final
  },
}

export const actions = {
  // settings for this distribution type, chosen decision tree
  setDistributionSettings({ commit }, mode) {
    commit('SET_DECISION_TREE', mode.tree)
    commit('setDistributionType', mode.batch)
  },

  // focus on the current page of rules
  processPageRules({ state, commit, dispatch }, current_page) {
    let rules
    try {
      rules = state.SELECTED_DECISION_TREE[current_page]
    } catch (e) {
      rules = null
    }

    commit('setPageRules', rules)

    try {
      if (containsKey(rules, 'final')) {
        // console.log('processing th page rules')
        commit('setIsFinalPage', true)
      } else {
        commit('setIsFinalPage', false)
      }

      // check for any feedback and process
      if (containsKey(rules, 'feedback')) {
        commit('feedback/setFeedbackRules', rules.feedback, {
          root: true,
        })
      }

      // check for any actions to complete on load
      if (containsKey(rules, 'load')) {
        if (containsKey(rules.load, 'score')) {
          dispatch(
            'processors/processScores/' + rules.load.score,
            {},
            {
              root: true,
            }
          )
        } else if (containsKey(rules.load, 'actions')) {
          dispatch(
            'feedback/' + rules.load.actions[0].key,
            {},
            {
              root: true,
            }
          )
        } else {
          dispatch('navigation/setNextPage', rules.load.next, {
            root: true,
          })
        }
      }
    } catch (e) {
      //  console.log('Error processing page rules', e)
    }
  },

  // handle scores from page form(s)/sets next page
  processAnswers({ state, dispatch }, payload) {
    // do if the page has been completed
    if (containsKey(state.rules, 'completed')) {
      if (containsKey(state.rules.completed, 'actions')) {
        //  feedback function with content
        dispatch('processActions', {
          answers: payload.answers,
          actions: state.rules.completed.actions[payload.question_no],
        })
      } else if (containsKey(state.rules.completed, 'process')) {
        //  feedback function without content
        dispatch('dispatchRequest', {
          key: 'feedback/' + state.rules.completed.process,
          value: 0,
        })
        return
      }

      // if processing required on completion
      if (containsKey(state.rules.completed, 'score')) {
        dispatch('processScores', state.rules.completed.score)
      } else {
        dispatch('navigation/setNextPage', state.rules.completed.next, {
          root: true,
        })
      }
    }
    // otherwise, specific element will need to meet conditions
    else if (containsKey(state.rules, 'conditions')) {
      // check if the condition relies on an answer within this component
      if (containsKey(state.rules.conditions, payload.data_id)) {
        const conditions = state.rules.conditions[payload.data_id]
        // check if this condition for data_id exists in rules
        for (let a = 0; a < conditions.length; a++) {
          // iterate through all answers on this page
          for (let b = 0; b < payload.answers.length; b++) {
            const curr_answer = payload.answers[b]

            // answer must meet the correct condition
            if (conditions[a].answer === curr_answer[conditions[a].index]) {
              // set the next page if answer found
              dispatch('navigation/setNextPage', conditions[a].next, {
                root: true,
              })

              // once next page is found,
              if ('actions' in conditions[a]) {
                dispatch('processActions', {
                  answers: conditions[a].answer,
                  actions: conditions[a].actions[payload.question_no],
                })
              }
            }
          }
        }
      }
    }
  },

  // changing profile state based on decision tree rules
  processActions({ dispatch }, payload) {
    // set the key to a value if specified
    if ('value' in payload.actions) {
      dispatch('dispatchRequest', {
        key: 'profile/' + payload.actions.key,
        value: payload.actions.value,
      })
      // otherwise set using the inputted data
    } else {
      dispatch('dispatchRequest', {
        key: 'profile/' + payload.actions.key,
        value: payload.answers,
      })
    }
  },

  // process score conditions set in the decision tree (todo: this is only used once but may be useful in future)
  processScores({ dispatch, rootGetters }, scores) {
    // for all scores, find any associated conditions
    for (let x = 0; x < scores.length; x++) {
      const cond = scores[x].conditions

      // find the associated attribute in state
      for (let b = 0; b < cond.length; b++) {
        const value = rootGetters['profile/' + scores[x].key + '']

        // check if values satisfy conditions todo: refactoring needed
        let confirmed = false
        if (cond[b].operator === 'greater') {
          confirmed = compare_greater(value, cond[b].answer)
        } else if (cond[b].operator === 'less') {
          confirmed = compare_less(value, cond[b].answer)
        } else {
          confirmed = compare_equal(value, cond[b].answer)
        }
        // if true, set the next page
        if (confirmed) {
          dispatch('navigation/setNextPage', cond[b].next, {
            root: true,
          })
        }
      }
    }
  },

  // route action/mutation to correct function/module
  dispatchRequest({ commit, dispatch }, pld) {
    if (pld.key in this._actions) {
      dispatch(pld.key, pld.value, {
        root: true,
      })
    } else {
      commit(pld.key, pld.value, {
        root: true,
      })
    }
  },

  // record when a user starts an activity todo: maybe remove this feature
  async startActivity({ rootGetters }, payload) {
    const user = rootGetters['auth/getUser']
    if (user) {
      await this.$ga.event(
        'Started ' + payload.eventCategory,
        payload.eventCategory,
        user.user_uuid,
        payload.eventCategory
      )
    }
  },

  // record when a user starts an activity todo: maybe remove this feature
  async endActivity({ rootGetters }, payload) {
    if (rootGetters['auth/getUser']) {
      await this.$ga.event(
        'Ended ' + payload.eventCategory,
        payload.eventCategory,
        rootGetters['auth/getUser'].user_uuid,
        payload.eventCategory
      )
    }
  },
}

export const getters = {
  getIsFinalPage: (state) => {
    return state.is_final_page
  },
}
