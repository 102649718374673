/*
 * Strictly necessary cookies
 */

// default state
const getDefaultState = () => {
  return {
    pwa: false,
  }
}

// initial state
export const state = getDefaultState()

// todo: review feedback display methodolgy, dependancy injection
export const mutations = {}

export const actions = {}

export const getters = {}
