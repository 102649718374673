export const ActivityCarousel = () => import('../../components/global/activities/ActivityCarousel.vue' /* webpackChunkName: "components/activity-carousel" */).then(c => wrapFunctional(c.default || c))
export const DastCarousel = () => import('../../components/global/activities/DastCarousel.vue' /* webpackChunkName: "components/dast-carousel" */).then(c => wrapFunctional(c.default || c))
export const DastFeedback = () => import('../../components/global/activities/DastFeedback.vue' /* webpackChunkName: "components/dast-feedback" */).then(c => wrapFunctional(c.default || c))
export const Quiz = () => import('../../components/global/activities/Quiz.vue' /* webpackChunkName: "components/quiz" */).then(c => wrapFunctional(c.default || c))
export const QuizFeedback = () => import('../../components/global/activities/QuizFeedback.vue' /* webpackChunkName: "components/quiz-feedback" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../components/global/activities/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const SmartGoal = () => import('../../components/global/activities/SmartGoal.vue' /* webpackChunkName: "components/smart-goal" */).then(c => wrapFunctional(c.default || c))
export const ValuesFeedback = () => import('../../components/global/activities/ValuesFeedback.vue' /* webpackChunkName: "components/values-feedback" */).then(c => wrapFunctional(c.default || c))
export const BodyFeedback = () => import('../../components/global/body/BodyFeedback.vue' /* webpackChunkName: "components/body-feedback" */).then(c => wrapFunctional(c.default || c))
export const Diagram = () => import('../../components/global/body/Diagram.vue' /* webpackChunkName: "components/diagram" */).then(c => wrapFunctional(c.default || c))
export const End = () => import('../../components/global/body/End.vue' /* webpackChunkName: "components/end" */).then(c => wrapFunctional(c.default || c))
export const DastResults = () => import('../../components/global/charts/DastResults.vue' /* webpackChunkName: "components/dast-results" */).then(c => wrapFunctional(c.default || c))
export const DonutChart = () => import('../../components/global/charts/DonutChart.vue' /* webpackChunkName: "components/donut-chart" */).then(c => wrapFunctional(c.default || c))
export const GaugeChart = () => import('../../components/global/charts/GaugeChart.vue' /* webpackChunkName: "components/gauge-chart" */).then(c => wrapFunctional(c.default || c))
export const LazyBarChart = () => import('../../components/global/charts/LazyBarChart.vue' /* webpackChunkName: "components/lazy-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const LazyRadarChart = () => import('../../components/global/charts/LazyRadarChart.vue' /* webpackChunkName: "components/lazy-radar-chart" */).then(c => wrapFunctional(c.default || c))
export const PointsOverview = () => import('../../components/global/charts/PointsOverview.vue' /* webpackChunkName: "components/points-overview" */).then(c => wrapFunctional(c.default || c))
export const SmartFeedback = () => import('../../components/global/charts/smartFeedback.vue' /* webpackChunkName: "components/smart-feedback" */).then(c => wrapFunctional(c.default || c))
export const ChoiceGroup = () => import('../../components/global/forms/ChoiceGroup.vue' /* webpackChunkName: "components/choice-group" */).then(c => wrapFunctional(c.default || c))
export const CopyCode = () => import('../../components/global/forms/CopyCode.vue' /* webpackChunkName: "components/copy-code" */).then(c => wrapFunctional(c.default || c))
export const GridSelection = () => import('../../components/global/forms/GridSelection.vue' /* webpackChunkName: "components/grid-selection" */).then(c => wrapFunctional(c.default || c))
export const InjunctiveNorms = () => import('../../components/global/forms/InjunctiveNorms.vue' /* webpackChunkName: "components/injunctive-norms" */).then(c => wrapFunctional(c.default || c))
export const LazyDatePicker = () => import('../../components/global/forms/LazyDatePicker.vue' /* webpackChunkName: "components/lazy-date-picker" */).then(c => wrapFunctional(c.default || c))
export const LifeValuesScores = () => import('../../components/global/forms/LifeValuesScores.vue' /* webpackChunkName: "components/life-values-scores" */).then(c => wrapFunctional(c.default || c))
export const PersonalGoals = () => import('../../components/global/forms/PersonalGoals.vue' /* webpackChunkName: "components/personal-goals" */).then(c => wrapFunctional(c.default || c))
export const ReasonsForUse = () => import('../../components/global/forms/ReasonsForUse.vue' /* webpackChunkName: "components/reasons-for-use" */).then(c => wrapFunctional(c.default || c))
export const SelectDrugs = () => import('../../components/global/forms/SelectDrugs.vue' /* webpackChunkName: "components/select-drugs" */).then(c => wrapFunctional(c.default || c))
export const SelectGroup = () => import('../../components/global/forms/SelectGroup.vue' /* webpackChunkName: "components/select-group" */).then(c => wrapFunctional(c.default || c))
export const SelectGroupPadless = () => import('../../components/global/forms/SelectGroupPadless.vue' /* webpackChunkName: "components/select-group-padless" */).then(c => wrapFunctional(c.default || c))
export const SliderCarousel = () => import('../../components/global/forms/SliderCarousel.vue' /* webpackChunkName: "components/slider-carousel" */).then(c => wrapFunctional(c.default || c))
export const SliderGroup = () => import('../../components/global/forms/SliderGroup.vue' /* webpackChunkName: "components/slider-group" */).then(c => wrapFunctional(c.default || c))
export const ValuesCarousel = () => import('../../components/global/forms/ValuesCarousel.vue' /* webpackChunkName: "components/values-carousel" */).then(c => wrapFunctional(c.default || c))
export const ReviewNon = () => import('../../components/global/review/ReviewNon.vue' /* webpackChunkName: "components/review-non" */).then(c => wrapFunctional(c.default || c))
export const ReviewSevere = () => import('../../components/global/review/ReviewSevere.vue' /* webpackChunkName: "components/review-severe" */).then(c => wrapFunctional(c.default || c))
export const ReviewUser = () => import('../../components/global/review/ReviewUser.vue' /* webpackChunkName: "components/review-user" */).then(c => wrapFunctional(c.default || c))
export const ChartFeedback = () => import('../../components/global/utility/ChartFeedback.vue' /* webpackChunkName: "components/chart-feedback" */).then(c => wrapFunctional(c.default || c))
export const Feedback = () => import('../../components/global/utility/Feedback.vue' /* webpackChunkName: "components/feedback" */).then(c => wrapFunctional(c.default || c))
export const FeedbackList = () => import('../../components/global/utility/FeedbackList.vue' /* webpackChunkName: "components/feedback-list" */).then(c => wrapFunctional(c.default || c))
export const FeedbackSlider = () => import('../../components/global/utility/FeedbackSlider.vue' /* webpackChunkName: "components/feedback-slider" */).then(c => wrapFunctional(c.default || c))
export const Recall = () => import('../../components/global/utility/Recall.vue' /* webpackChunkName: "components/recall" */).then(c => wrapFunctional(c.default || c))
export const RecallGoals = () => import('../../components/global/utility/RecallGoals.vue' /* webpackChunkName: "components/recall-goals" */).then(c => wrapFunctional(c.default || c))
export const RecallInfluences = () => import('../../components/global/utility/RecallInfluences.vue' /* webpackChunkName: "components/recall-influences" */).then(c => wrapFunctional(c.default || c))
export const RecallList = () => import('../../components/global/utility/RecallList.vue' /* webpackChunkName: "components/recall-list" */).then(c => wrapFunctional(c.default || c))
export const RecallListTitle = () => import('../../components/global/utility/RecallListTitle.vue' /* webpackChunkName: "components/recall-list-title" */).then(c => wrapFunctional(c.default || c))
export const RecallNegativesList = () => import('../../components/global/utility/RecallNegativesList.vue' /* webpackChunkName: "components/recall-negatives-list" */).then(c => wrapFunctional(c.default || c))
export const RecallPosNegs = () => import('../../components/global/utility/RecallPosNegs.vue' /* webpackChunkName: "components/recall-pos-negs" */).then(c => wrapFunctional(c.default || c))
export const RecallSimpleList = () => import('../../components/global/utility/RecallSimpleList.vue' /* webpackChunkName: "components/recall-simple-list" */).then(c => wrapFunctional(c.default || c))
export const Resources = () => import('../../components/global/utility/Resources.vue' /* webpackChunkName: "components/resources" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveImage = () => import('../../components/global/utility/ResponsiveImage.vue' /* webpackChunkName: "components/responsive-image" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveVideo = () => import('../../components/global/utility/ResponsiveVideo.vue' /* webpackChunkName: "components/responsive-video" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavArrows = () => import('../../components/layout/NavArrows.vue' /* webpackChunkName: "components/layout-nav-arrows" */).then(c => wrapFunctional(c.default || c))
export const LayoutProgressBar = () => import('../../components/layout/ProgressBar.vue' /* webpackChunkName: "components/layout-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsChartsCompareChart = () => import('../../components/lazyloads/charts/CompareChart.vue' /* webpackChunkName: "components/lazyloads-charts-compare-chart" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsCookieBanner = () => import('../../components/lazyloads/dialogs/CookieBanner.vue' /* webpackChunkName: "components/lazyloads-dialogs-cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsCookieDialog = () => import('../../components/lazyloads/dialogs/CookieDialog.vue' /* webpackChunkName: "components/lazyloads-dialogs-cookie-dialog" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsLazyInfoDialog = () => import('../../components/lazyloads/dialogs/LazyInfoDialog.vue' /* webpackChunkName: "components/lazyloads-dialogs-lazy-info-dialog" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsLazyLoginUserDialog = () => import('../../components/lazyloads/dialogs/LazyLoginUserDialog.vue' /* webpackChunkName: "components/lazyloads-dialogs-lazy-login-user-dialog" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsLogoutDialog = () => import('../../components/lazyloads/dialogs/LogoutDialog.vue' /* webpackChunkName: "components/lazyloads-dialogs-logout-dialog" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsNotifierDialog = () => import('../../components/lazyloads/dialogs/NotifierDialog.vue' /* webpackChunkName: "components/lazyloads-dialogs-notifier-dialog" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsRevisitDialog = () => import('../../components/lazyloads/dialogs/RevisitDialog.vue' /* webpackChunkName: "components/lazyloads-dialogs-revisit-dialog" */).then(c => wrapFunctional(c.default || c))
export const LazyloadsDialogsPwaDialog = () => import('../../components/lazyloads/dialogs/pwaDialog.vue' /* webpackChunkName: "components/lazyloads-dialogs-pwa-dialog" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
